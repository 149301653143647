import PropTypes from 'prop-types';
import React, { useCallback, useContext, useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { BaseUrlContext, UserContext, ConfigContext } from '@core/context';
import useEnvInfo from '@core/hooks/useEnvInfo';
import useUserPermissions from '@core/hooks/useUserPermissions';

import Box from '@ui/Box';
import Button from '@ui/Button';
import DateLine from '@ui/DateLine';
import Flex from '@ui/Flex';
import Input from '@ui/Input';
import MarkdownEditor from '@ui/MarkdownEditor';
import Modal, { ModalBody, ModalFooter } from '@ui/Modal';
import { notify, ErrorNotification } from '@ui/Notification';
import RDMD from '@ui/RDMD';
import Title from '@ui/Title';
import Toggle from '@ui/Toggle';

import { handleAddTag, handleDelete, handleDeleteTag, handleFAQ, handleUpdate, handlePostVote } from '../helpers';
import { rdmdOpts } from '../options';
import classes from '../style.module.scss';

const Metabox = props => {
  const { userVote, setUserVote, discussUrl, permissions, setErrorMessage, userId } = props;

  return (
    <Flex className={classes['DiscussPost-metabox']} gap="xs" layout="col">
      <Button
        className={classes['DiscussPost-button']}
        kind={userVote.hasVoted ? 'primary' : 'secondary'}
        onClick={e => {
          // Don't navigate to the post if we've clicked on the vote button! Instead, submit a vote.
          e.preventDefault();
          return handlePostVote({ permissions, setErrorMessage, userVote, setUserVote, userId, discussUrl });
        }}
        outline
        size="md"
      >
        <span className={`${classes['DiscussPost-button_icon']} icon-thumbs-up-2`} /> {userVote.voteCount || 0}
      </Button>
    </Flex>
  );
};

const PostDetails = props => {
  const {
    isAdminUser,
    isLoggedIn,
    postUser: { name, email },
    post: { edited },
    createdAt,
  } = props;

  const Date = () => (
    <DateLine
      className={classes['DiscussPost-date']}
      icon="icon-clock"
      suffix={`by ${name}`}
      tag="span"
      time={createdAt}
    />
  );

  const Email = () => (
    <span className={classes['DiscussPost-email']}>
      <span className={`${classes.icon} icon-lock1`} />
      <span>{email}</span>
    </span>
  );

  return (
    <div className={classes['DiscussPost-details']}>
      <Date />
      {!!isLoggedIn && !!isAdminUser && !!email && <Email />}
      {!!edited && <span className={classes['DiscussPost-edited']}>(edited)</span>}
    </div>
  );
};

const PostContent = props => {
  const { discussUrl, isAdminUser, post, postData, setErrorMessage, setPostData, setIsEditing, userId } = props;
  const isAuthor = userId && userId === post.hub2user?._id;
  const history = useHistory();
  const modalRef = useRef();

  const UpdateFAQButton = () => (
    <Button
      className={classes['DiscussPostActions-action']}
      kind="secondary"
      onClick={() => handleFAQ({ discussUrl, postData, setErrorMessage, setPostData })}
      outline
      size="sm"
    >
      <i className={`${classes.DiscussPost_icon} ${postData.isFAQ ? 'icon-cross' : 'icon-plus1'}`} />
      {postData.isFAQ ? 'Remove from FAQ' : 'Add to FAQ'}
    </Button>
  );

  const EditPostButton = () => (
    <Button
      className={classes['DiscussPostActions-action']}
      kind="secondary"
      onClick={() => setIsEditing(true)}
      outline
      size="sm"
    >
      <i className={`${classes.DiscussPost_icon} icon-edit-2`} />
      Edit
    </Button>
  );

  const DeletePostButton = () => (
    <Button
      className={classes['DiscussPostActions-action']}
      kind="secondary"
      onClick={() => {
        modalRef.current.toggle(true);
      }}
      outline
      size="sm"
    >
      <i className={`${classes.DiscussPost_icon} icon-trash1`} />
      Delete
    </Button>
  );

  const ConfirmDeleteModal = () => {
    const [markAsSpam, setMarkAsSpam] = useState(false);

    return (
      <Modal ref={modalRef} size="sm" target="#delete-discussion-post-modal-target" verticalCenter>
        <ModalBody>
          <Title className={classes['DiscussPost-delete_text']} level={6}>
            Are you sure you want to delete this post?
          </Title>
        </ModalBody>
        <ModalFooter justify="center">
          <Button
            kind="secondary"
            onClick={() => {
              modalRef.current.toggle(false);
            }}
          >
            Cancel
          </Button>
          <Button
            kind="secondary"
            onClick={() => {
              handleDelete({ url: discussUrl, redirect: () => history.push('/discuss'), setErrorMessage, markAsSpam });
              modalRef.current.toggle(false);
            }}
          >
            Delete
          </Button>
        </ModalFooter>
        <ModalFooter justify="center">
          <Toggle
            checked={markAsSpam}
            label="Mark this post as spam"
            onChange={() => setMarkAsSpam(!markAsSpam)}
            type="checkbox"
          />
        </ModalFooter>
      </Modal>
    );
  };

  return (
    <div>
      <RDMD className={classes['DiscussPost-fullbody']} opts={rdmdOpts}>
        {postData.body}
      </RDMD>
      <div className={classes.DiscussPostActions}>
        <Tags {...{ discussUrl, postData, setPostData, isAdminUser, setErrorMessage }} />
        {!!isAdminUser && <UpdateFAQButton />}
        {!!(isAdminUser || isAuthor) && <EditPostButton />}
        {!!(isAdminUser || isAuthor) && (
          <>
            <DeletePostButton />
            <ConfirmDeleteModal />
          </>
        )}
      </div>
    </div>
  );
};

const PostContentEditor = props => {
  const {
    discussUrl,
    setPostData,
    setIsEditing,
    setErrorMessage,
    postData: { body: postDataBody },
  } = props;
  const baseUrl = useContext(BaseUrlContext);
  const { domainFull } = useContext(ConfigContext);
  const [editor, setEditor] = useState();

  const onInit = useCallback(e => setEditor(e), []);
  const onChange = useCallback((_, e) => setEditor(e), []);

  return (
    <>
      <Box className={classes.DiscussEditorWrapper} kind="rule">
        <MarkdownEditor
          basic
          className={classes['DiscussPost-fullbody']}
          doc={postDataBody}
          domainFull={domainFull}
          imageUpload={false}
          onChange={onChange}
          onInit={onInit}
          projectBaseUrl={baseUrl}
        />
      </Box>
      <div className={classes['DiscussPost-edit']}>
        <Button
          className={`${classes['Discuss-question_button']} ${classes['DiscussPost-edit_button']}`}
          onClick={() => {
            const editedBody = editor?.toString() || '';
            return handleUpdate({
              url: discussUrl,
              editedBody,
              setErrorMessage,
              updateSuccess: () => {
                setPostData(prevState => ({ ...prevState, body: editedBody }));
                setIsEditing(false);
              },
            });
          }}
          size="sm"
        >
          Save
        </Button>
        <Button kind="secondary" onClick={() => setIsEditing(false)} outline size="sm">
          Cancel
        </Button>
      </div>
    </>
  );
};

const Tags = props => {
  const { discussUrl, postData, setPostData, isAdminUser, setErrorMessage } = props;
  const [newTag, setNewTag] = useState('');
  const [isAddingTag, setIsAddingTag] = useState(false);

  const AddTagButton = () => (
    <Button
      className={classes['DiscussPostActions-action']}
      kind="secondary"
      onClick={() => setIsAddingTag(true)}
      outline
      size="sm"
    >
      <i className={`${classes.DiscussPost_icon} icon-plus1`} />
      Tag
    </Button>
  );

  return (
    <>
      {postData.tags?.map((tag, i) => {
        return (
          <span key={`tag-${tag}-${i}`} className={classes.DiscussPostActions_wrapper}>
            {!!isAdminUser && (
              <span
                className={`${classes.DiscussPostActions_delete} icon-cross`}
                onClick={() => handleDeleteTag({ tag, discussUrl, setErrorMessage, setPostData, postData })}
                onKeyDown={e => handleDeleteTag({ e, tag, discussUrl, setErrorMessage, setPostData, postData })}
                role="button"
                tabIndex={0}
              />
            )}
            <Button
              className={classes.DiscussPostActions_button}
              kind="secondary"
              outline
              size="sm"
              to={`/discuss?tag=${tag}`}
            >
              {tag}
            </Button>
          </span>
        );
      })}
      {!!isAdminUser &&
        (isAddingTag ? (
          <Input
            autoFocus
            className={classes.DiscussPostActions_button}
            onBlur={() => setIsAddingTag(false)}
            onChange={e => setNewTag(e.target.value)}
            onKeyUp={e =>
              handleAddTag({ e, discussUrl, newTag, setNewTag, setErrorMessage, setIsAddingTag, setPostData, postData })
            }
            placeholder="Enter Tag"
            value={newTag}
          />
        ) : (
          <AddTagButton />
        ))}
    </>
  );
};

const DiscussFull = ({ baseUrl, post, userVote, setUserVote }) => {
  const { effective_user: postUser, body, title, id, tags, isFAQ, createdAt } = post;
  const { isClient } = useEnvInfo;
  const { permissions, _id: userId } = useContext(UserContext);
  const { isLoggedIn, isAdminUser } = useUserPermissions();

  const [postData, setPostData] = useState({
    body,
    isFAQ,
    tags,
  });
  const [isEditing, setIsEditing] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    const err = errorMessage?.errors?.title?.message || errorMessage?.message;
    return err ? notify(<ErrorNotification>{err}</ErrorNotification>) : undefined;
  }, [errorMessage]);

  const discussUrl = `${isClient ? window.location.pathname : `${baseUrl}/discuss/${id}`}`;

  return (
    <div className={classes.DiscussPost}>
      <Metabox {...{ userVote, setUserVote, discussUrl, permissions, setErrorMessage, userId }} />
      <div className={classes['DiscussPost-content']}>
        <h2 className={classes['DiscussPost-title']}>{title}</h2>
        {!!createdAt && <PostDetails {...{ isAdminUser, isLoggedIn, postUser, post, createdAt }} />}
        {isEditing ? (
          <PostContentEditor {...{ discussUrl, setPostData, setIsEditing, postData, setErrorMessage }} />
        ) : (
          <PostContent
            {...{
              discussUrl,
              isAdminUser,
              post,
              postData,
              setPostData,
              setIsEditing,
              userId,
              setErrorMessage,
            }}
          />
        )}
      </div>
      <div className="ModalWrapper" id="delete-discussion-post-modal-target"></div>
    </div>
  );
};

DiscussFull.propTypes = {
  baseUrl: PropTypes.string,
  post: PropTypes.object,
  setUserVote: PropTypes.func,
  userVote: PropTypes.shape({
    hasVoted: PropTypes.bool,
    voteCount: PropTypes.number,
    voters: PropTypes.array,
  }),
};

Metabox.propTypes = {
  discussUrl: PropTypes.string,
  permissions: PropTypes.array,
  setErrorMessage: PropTypes.func,
  setUserVote: PropTypes.func,
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  userVote: PropTypes.shape({
    hasVoted: PropTypes.bool,
    voteCount: PropTypes.number,
    voters: PropTypes.array,
  }),
};

PostContent.propTypes = {
  discussUrl: PropTypes.string,
  isAdminUser: PropTypes.bool,
  post: PropTypes.object,
  postData: PropTypes.shape({
    body: PropTypes.string,
    isFAQ: PropTypes.bool,
  }),
  setErrorMessage: PropTypes.func,
  setIsEditing: PropTypes.func,
  setPostData: PropTypes.func,
  userId: PropTypes.string,
};

PostContentEditor.propTypes = {
  discussUrl: PropTypes.string,
  postData: PropTypes.shape({
    body: PropTypes.string,
  }),
  setErrorMessage: PropTypes.func,
  setIsEditing: PropTypes.func,
  setPostData: PropTypes.func,
};

PostDetails.propTypes = {
  createdAt: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  isAdminUser: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  post: PropTypes.shape({
    edited: PropTypes.bool,
  }),
  postUser: PropTypes.shape({
    email: PropTypes.string,
    name: PropTypes.string,
  }),
};

Tags.propTypes = {
  discussUrl: PropTypes.string,
  isAdminUser: PropTypes.bool,
  postData: PropTypes.shape({
    tags: PropTypes.array,
  }),
  setErrorMessage: PropTypes.func,
  setPostData: PropTypes.func,
};

export default DiscussFull;
