const handleAddComment = ({
  recaptchaResponse = '',
  url,
  anonymous,
  body,
  parent,
  commentReopened = false,
  commentSolved = false,
  user,
  setErrorMessage,
  updateSuccess,
}) => {
  return fetch(url, {
    body: JSON.stringify({
      body,
      anonymous,
      parent,
      ...(recaptchaResponse && { 'g-recaptcha-response': recaptchaResponse }),
      ...(commentReopened && { reopened: commentReopened }),
      ...(commentSolved && { solved: commentSolved }),
      user,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
  })
    .then(async res => {
      if (res.ok) {
        updateSuccess(await res.json());
      } else {
        setErrorMessage(await res.json());
      }
    })
    .catch(err => setErrorMessage({ message: err }));
};

const handleAddTag = ({ e, discussUrl, newTag, setErrorMessage, setNewTag, setIsAddingTag, setPostData, postData }) => {
  // if user hits enter and tag is valid, add it
  if (e.keyCode === 13 && newTag.length > 0) {
    return fetch(discussUrl, {
      body: JSON.stringify({ tags: [...postData.tags, newTag] }),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'PUT',
    })
      .then(res => {
        if (res.ok) {
          setPostData(prevState => ({ ...prevState, tags: [...postData.tags, newTag] }));
          setNewTag('');
          setIsAddingTag(false);
        }
      })
      .catch(err => setErrorMessage({ message: err }));
  }

  // if user hits esc, reset the tag and hide the input box
  if (e.keyCode === 27) {
    setNewTag('');
    setIsAddingTag(false);
  }

  return null;
};

const handleDelete = ({ url, redirect, setErrorMessage, setPostComments, updatedPostComments, markAsSpam }) => {
  return fetch(url, {
    body: JSON.stringify({ markAsSpam }),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'DELETE',
  })
    .then(res => {
      if (res.ok) {
        if (redirect) redirect();
        else setPostComments(updatedPostComments);
      }
    })
    .catch(err => setErrorMessage({ message: err }));
};

const handleDeleteTag = ({ tag, e, discussUrl, setErrorMessage, setPostData, postData }) => {
  // If the user is tabbing, only actually delete the tag if they've hit enter
  if (e?.keyCode && e?.keyCode !== 13) return null;

  const updatedTags = postData.tags.filter(t => t !== tag);
  return fetch(discussUrl, {
    body: JSON.stringify({ tags: updatedTags }),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'PUT',
  })
    .then(res => {
      if (res.ok) {
        setPostData(prevState => ({ ...prevState, tags: updatedTags }));
      }
    })
    .catch(err => setErrorMessage({ message: err }));
};

const handleFAQ = ({ discussUrl, postData, setErrorMessage, setPostData }) => {
  return fetch(discussUrl, {
    body: JSON.stringify({ isFAQ: !postData.isFAQ }),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'PUT',
  })
    .then(res => {
      if (res.ok) {
        setPostData(prevState => ({ ...prevState, isFAQ: !postData.isFAQ }));
      }
    })
    .catch(err => setErrorMessage({ message: err }));
};

const handleUpdate = ({ url, editedBody, setErrorMessage, updateSuccess }) => {
  return fetch(url, {
    body: JSON.stringify({ body: editedBody }),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'PUT',
  })
    .then(res => {
      if (res.ok) {
        updateSuccess();
      }
    })
    .catch(err => setErrorMessage({ message: err }));
};

const handleCommentVote = ({ url, permissions, voteType, voteData, setErrorMessage, setVoteData, userId }) => {
  // Redirect the user to the log in page if necessary
  if (!permissions) {
    window.location.assign(`/login?redirect_uri=${window.location.pathname}`);
    return null;
  }

  let destroyVote = true;
  let reqBody = '';
  const destroyUrl = `${url}/destroy`;

  // If the current user has already voted in either direction, this removes their vote
  const allUpdatedVotes = {};
  Object.keys(voteData).forEach(type => {
    allUpdatedVotes[type] = voteData[type].filter(vote => vote.user !== userId);
  });

  // if the current user hasn't already voted in this direction, we add their vote
  if (voteData[voteType].length === allUpdatedVotes[voteType]?.length) {
    destroyVote = false;
    const voteNum = voteType === 'upVote' ? 1 : -1;
    allUpdatedVotes[voteType].push({ user: userId, value: voteNum });
    reqBody = { body: JSON.stringify({ voted: voteNum }) };
  }

  return fetch(destroyVote ? destroyUrl : url, {
    ...reqBody,
    headers: {
      'Content-Type': 'application/json',
    },
    method: destroyVote ? 'DELETE' : 'POST',
  })
    .then(res => {
      if (res.ok) setVoteData(allUpdatedVotes);
    })
    .catch(err => setErrorMessage({ message: err }));
};

const handlePostVote = ({ permissions, userVote, setErrorMessage, setUserVote, userId, discussUrl }) => {
  // Redirect the user to the log in page if necessary
  if (!permissions) {
    window.location.assign(`/login?redirect_uri=${window.location.pathname}`);
    return null;
  }

  // If the current user has already voted, remove their vote. Otherwise, add it.
  let currentVoters = userVote.voters;
  if (userVote.hasVoted) currentVoters = currentVoters.filter(voterId => voterId !== userId);
  else currentVoters.push(userId);

  const discussVoteUrl = `${discussUrl}/vote`;

  return fetch(discussVoteUrl, {
    body: JSON.stringify({ hub2voters: currentVoters, voted: !userVote.hasVoted }),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
  })
    .then(res => {
      if (res.ok) setUserVote({ voteCount: currentVoters.length, hasVoted: !userVote.hasVoted, voters: currentVoters });
    })
    .catch(err => setErrorMessage({ message: err }));
};

const handleCreatePost = ({
  csrfToken,
  post,
  loggedOutUser = {},
  url,
  recaptchaResponse = '',
  setErrorMessage,
  forceRecaptcha,
  setCreatePostIsLoading,
  setForceRecaptcha,
  redirect,
}) =>
  fetch(url, {
    body: JSON.stringify({
      _csrf: csrfToken,
      title: post.title,
      body: post.body,
      ...(loggedOutUser && { name: loggedOutUser.name, email: loggedOutUser.email }),
      ...(recaptchaResponse && { 'g-recaptcha-response': recaptchaResponse }),
      ...(forceRecaptcha && { recaptchaResponse: true }),
    }),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
  })
    .then(async res => {
      if (res.ok) {
        const discussion = await res.json();
        redirect(discussion._id);
      } else {
        setCreatePostIsLoading(false);
        // if the post gets marked as spam by oopspam, we should engage recaptcha
        setForceRecaptcha(true);
        setErrorMessage(await res.json());
      }
    })
    .catch(err => {
      setCreatePostIsLoading(false);
      setErrorMessage({ message: err });
    });

export {
  handleAddComment,
  handleAddTag,
  handleDelete,
  handleDeleteTag,
  handleFAQ,
  handleUpdate,
  handleCommentVote,
  handlePostVote,
  handleCreatePost,
};
